<template>
  <div style="text-align: justify">
    <p class="mb-5">
      a) Write the equilibrium expression for a solution of calcium sulfate,
      <chemical-latex content="CaSO4," />
      in water.
    </p>
    <p class="mb-5">
      b) Write the equation relating the solubility product,
      <stemble-latex content="$\text{K}_{\text{sp}},$" />
      for
      <chemical-latex content="CaSO4" />
      to the solubility,
      <stemble-latex content="$\text{S,}$" />
      of the compound.
    </p>
    <p class="mb-3">
      c) <latex-number number="50.00" unit="\text{mL}" />
      of a standard solution of calcium sulfate in water was passed through an ion-exchange resin
      (in the acid form). The protons released were collected and titrated against a standard
      solution of
      <latex-number :number="concNaOH" unit="\text{M}" />
      <chemical-latex content="NaOH\text{.}" />
      The endpoint of the titration occurred after the addition of
      <number-value :value="volTitration" unit="\text{mL}" />
      of
      <chemical-latex content="NaOH\text{.}" />
    </p>
    <p class="mb-3">Calculate:</p>
    <p class="mb-3 pl-8">
      i) The molar solubility,
      <stemble-latex content="$\text{S,}$" />
      of
      <stemble-latex content="$\text{CaSO4}$" />
    </p>

    <p class="mb-5 pl-8">
      ii) The value of the solubility product,
      <stemble-latex content="$\text{K}_{\text{sp}},$" />
      for calcium sulfate.
    </p>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';
import LatexNumber from '../displayers/LatexNumber';
import NumberValue from '../NumberValue';

export default {
  name: 'UPEIKspPreLab',
  components: {
    ChemicalLatex,
    StembleLatex,
    LatexNumber,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  computed: {
    volTitration() {
      return this.taskState.getValueBySymbol('volTitration').content;
    },
    Ksp() {
      // Back-end value is scaled by factor of 50
      return this.taskState.getValueBySymbol('Ksp').content;
    },
    concNaOH() {
      return (
        (Math.sqrt(this.Ksp.toFloat() / 50) * 2 * 0.05 * 1000) /
        this.volTitration.toFloat()
      ).toFixed(5);
    },
  },
};
</script>
